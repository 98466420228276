<span
  *ngIf="!format && (currency$ | async) as currency"
  [class.line-through]="discount"
  [class.font-bold]="bold"
  [class.font-semibold]="semiBold"
  [class.text-neutral-600]="discount"
  [class.kiosk:text-inherit]="discount"
  class="font-base font-normal lining-nums tabular-nums
        {{
    size === 'SM'
      ? 'text-sm lg:text-base'
      : size === 'MD'
        ? 'text-md'
        : size === 'L'
          ? 'text-lg'
          : size === 'XL'
            ? 'text-xl'
            : size === '2XL'
              ? 'text-2xl'
              : 'text-base'
  }}"
>
  <ng-container *ngIf="withParenthesis">(</ng-container>
  <span *ngIf="displayPlus && amount >= 0">+</span>

  {{
    amount | currency: currency : 'symbol' : undefined : (currency === 'EUR' ? 'fr-FR' : 'en-US')
  }}
  <ng-container *ngIf="withParenthesis">)</ng-container>
</span>

<span
  *ngIf="format && (currency$ | async) as currency"
  [class.line-through]="discount"
  [class.font-bold]="bold"
  [class.font-semibold]="semiBold"
  [class.text-neutral-600]="discount"
  [class.kiosk:text-inherit]="discount"
  class=" lining-nums tabular-nums
        {{
    size === 'SM'
      ? 'text-sm'
      : size === 'MD'
        ? 'text-md'
        : size === 'L'
          ? 'text-lg'
          : size === 'XL'
            ? 'text-xl'
            : size === '2XL'
              ? 'text-2xl'
              : size === '3XL'
                ? 'text-3xl'
                : size === '4XL'
                ? 'text-4xl'
                  : 'text-base'
  }}"
>
  <ng-container *ngIf="withParenthesis">(</ng-container>
  {{
    amount / 100
      | currency: currency : 'symbol' : undefined : (currency === 'EUR' ? 'fr-FR' : 'en-US')
  }}
  <ng-container *ngIf="withParenthesis">)</ng-container>
</span>
