import { Component, Input, OnInit } from '@angular/core';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { DoodOrderModel } from '@store/order/order.model';
import {
  IPaymentMethod,
  KioskPaymentMethodCard,
  PaymentMethodHandles,
} from '@config/payment-methods.config';
import { OrderKeys } from '@config/keys/order.keys';
import {
  PAYMENT_STORE_BUTTON_INITIAL_STATE,
  PAYMENT_STORE_STATUS_INITIAL_STATE,
} from '@store/payment';
import { Subject } from 'rxjs';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { ErrorService } from '@core/services/error-service/error.service';
import { PaymentStoreRefiner } from '@common/refiners/payment.refiner';
import { PaymentStoreSelector } from '@common/selectors/payment.selector';
import { OrderStoreDispatcher } from '@common/dispatchers/order.dispatcher';
import { PaymentService } from '@core/services/payment/payment.service';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { KioskPaymentMethodPickerStep } from '@shared/blocks/kiosk-payment-method-picker-block/kiosk-payment-method-picker-step-enum';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kiosk-payment-method-picker-block',
  templateUrl: './kiosk-payment-method-picker-block.component.html',
  styles: [],
})
export class KioskPaymentMethodPickerBlockComponent implements OnInit {
  @Input() header: any;

  @Input() headingChoosePaymentMethod = 'kiosk-payment-method-picker.choice_heading';
  @Input() choicePayOnSite = 'kiosk-payment-method-picker.choice_pay_on_site';
  @Input() choicePayOnKiosk = 'kiosk-payment-method-picker.choice_pay_on_kiosk';
  @Input() payOnKioskText = 'kiosk-payment-method-picker.pay_on_kiosk_text';
  @Input() displayPayOnKioskImage = true;
  @Input() payOnKioskImage?: IContentBuilderFieldImage = {
    url: 'assets/images/kiosk-default-pay-on-kiosk.png',
  };
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() cardInactiveBackgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() cardInactiveTextColor: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() cardActiveBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() cardActiveTextColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  order$ = this.orderSelector.selectValidation;
  availablePaymentMethods$ = this.paymentRefiner.selectPaymentMethodsWithDescriptors;
  isPaymentRetryInProgress$ = this.paymentSelector.selectStatus.pipe(map(s => s.isRetryInProgress));
  activeOrderCreatedAt$ = this.order$.pipe(
    map(order => (order ? (order as DoodOrderModel).created_at : null)),
  );

  availablePaymentMethods?: IPaymentMethod[];
  selectedPaymentMethod: IPaymentMethod | null = null;
  orderKeys = OrderKeys;
  kioskPaymentMethodPickerStep = KioskPaymentMethodPickerStep;

  protected readonly errorMessages$ = this.errorService.getErrorMessage$();
  protected readonly isOrderValidation$ = this.orderSelector.selectIsValidating;

  error: string | null = null;
  amount: number | null = null;
  button = PAYMENT_STORE_BUTTON_INITIAL_STATE;
  status = PAYMENT_STORE_STATUS_INITIAL_STATE;

  private _destroyerRef = new Subject<boolean>();
  protected paymentMethodCards: KioskPaymentMethodCard[] = [];
  protected selectedCard?: KioskPaymentMethodCard;
  protected step: KioskPaymentMethodPickerStep = KioskPaymentMethodPickerStep.CHOICE;
  protected displaySubmitButton = true;

  constructor(
    private orderSelector: OrderStoreSelector,
    private readonly errorService: ErrorService,
    private paymentRefiner: PaymentStoreRefiner,
    private paymentSelector: PaymentStoreSelector,
    private orderDispatcher: OrderStoreDispatcher,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {
    this.paymentSelector.select
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(({ amount, button, error, status }) => {
        this.status = status;
        this.button = button;
        this.amount = amount;
        this.error = error;
      });
  }

  ngOnInit(): void {
    this.paymentDispatcher.resetUI();
    this.loadAvailablePaymentMethods();

    // TODO: Change this by a selector ?
    if (this.orderSelector.active) {
      this.orderDispatcher.resetActive();
    }
  }

  private loadAvailablePaymentMethods() {
    this.availablePaymentMethods$
      .pipe(
        tap(availablePaymentMethods => {
          availablePaymentMethods = availablePaymentMethods || [];
          availablePaymentMethods.forEach(a => {
            a.data = {
              ...a.data,
              isUnique: availablePaymentMethods.length === 1,
            };
          });

          this.availablePaymentMethods = availablePaymentMethods;

          this.paymentMethodCards = [];

          availablePaymentMethods.forEach(paymentMethod => {
            if (paymentMethod.handle === PaymentMethodHandles.OnsiteWithoutDeposit) {
              this.paymentMethodCards.push({
                label: this.choicePayOnSite,
                icon: 'icon-kiosk-icon-coins',
                paymentMethod: paymentMethod,
                hasNextScreen: false,
              });
            }

            if (
              [
                PaymentMethodHandles.YavinTerminal,
                PaymentMethodHandles.AuresEtkTerminal,
                PaymentMethodHandles.StripeTerminal,
                PaymentMethodHandles.AdyenTerminal,
              ].includes(paymentMethod.handle)
            ) {
              this.paymentMethodCards.push({
                label: this.choicePayOnKiosk,
                icon: 'icon-kiosk-icon-card',
                paymentMethod: paymentMethod,
                hasNextScreen: true,
              });
            }

            if (availablePaymentMethods.length === 1) {
              this.selectedCard = this.paymentMethodCards[0];
              this.submit();
              return;
            }
          });
        }),
      )
      .subscribe();
  }

  selectPaymentMethod(paymentMethod: IPaymentMethod): void {
    this.isPaymentRetryInProgress$
      .pipe(
        take(1),
        tap(isPaymentRetryInProgress => {
          if (isPaymentRetryInProgress) {
            return;
          }
          if (this.selectedPaymentMethod === paymentMethod) {
            this.selectedPaymentMethod = null;
            this.paymentDispatcher.resetUI();
            return;
          }
          this.selectedPaymentMethod = paymentMethod;
        }),
      )
      .subscribe();
  }

  triggerPayment(): void {
    this.paymentService.triggerPayment();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  selectCard(card: KioskPaymentMethodCard) {
    if (this.selectedCard === card) {
      this.selectedCard = undefined;
    } else {
      this.selectedCard = card;
    }
  }

  submit() {
    if (!this.selectedCard) {
      return;
    }

    if (this.step === KioskPaymentMethodPickerStep.CHOICE) {
      if (this.selectedCard.hasNextScreen) {
        this.step = KioskPaymentMethodPickerStep.TERMINAL;
        this.displaySubmitButton = false;
      } else {
        this.paymentService.triggerPayment();
      }
      return;
    }

    if (this.step === KioskPaymentMethodPickerStep.TERMINAL) {
      this.paymentService.triggerPayment();
      return;
    }
  }

  back() {
    if (this.step === KioskPaymentMethodPickerStep.TERMINAL) {
      this.selectedCard = undefined;
      this.step = KioskPaymentMethodPickerStep.CHOICE;
      this.displaySubmitButton = true;
      return;
    }

    if (this.step === KioskPaymentMethodPickerStep.CHOICE) {
      this.router.navigate([this.routerHelper.translateRoute('/cart')]);
      return;
    }
  }
}
