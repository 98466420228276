<div class="flex min-h-screen flex-col justify-between">
  <!--  Header -->

  <div class="">
    <app-block-list [blockIdList]="header"></app-block-list>

    <div
      class="mx-5 flex items-center justify-start gap-3 text-xl text-neutral-500"
      [class.invisible]="!(isOrderValidation$ | async)"
    >
      <span>{{ 'kiosk.cart.orderValidationInProgress' | translate }}</span>
      <img src="assets/images/spinner.svg" />
    </div>
    <div class="my-5">
      <p
        class="mx-5 rounded bg-error-50 px-4 py-3 font-base text-error-700"
        *ngFor="let message of errorMessages$ | async"
      >
        {{ message }}
      </p>
    </div>
  </div>

  @let isOrderValid = !!(order$ | async)?.final_price;

  @if (isOrderValid) {
    <!--  Choices -->
    <ng-container *ngIf="step === kioskPaymentMethodPickerStep.CHOICE">
      <div class="flex flex-grow flex-col items-center justify-center">
        <!-- Choice heading -->
        <h1
          class="mb-24 text-center text-6xl font-bold"
          [style.color]="headingColor | contentBuilderColor"
        >
          {{ headingChoosePaymentMethod | translate }}
        </h1>

        <!-- Choice cards -->
        <div
          class="grid gap-12 pb-64"
          [ngClass]="{
            'grid-cols-2 w-8/12': paymentMethodCards.length > 1,
            'grid-cols-1 w-4/12': paymentMethodCards.length === 1,
          }"
        >
          <div *ngFor="let card of paymentMethodCards">
            <div
              class="flex h-full w-full cursor-pointer flex-col items-center justify-center gap-8 rounded-lg border-4 border-solid px-10 py-12 text-center text-4.5xl"
              [style.color]="
                selectedCard === card
                  ? (cardActiveTextColor | contentBuilderColor)
                  : (cardInactiveTextColor | contentBuilderColor)
              "
              [style.background-color]="
                selectedCard === card
                  ? (cardActiveBackgroundColor | contentBuilderColor)
                  : (cardInactiveBackgroundColor | contentBuilderColor)
              "
              [style.border-color]="
                selectedCard === card
                  ? (cardActiveTextColor | contentBuilderColor)
                  : (cardInactiveTextColor | contentBuilderColor)
              "
              (click)="selectCard(card)"
            >
              <i
                class="icon text-5xl"
                *ngIf="card.icon"
                [ngClass]="{
                  'icon-kiosk-icon-coins': card.icon === 'icon-kiosk-icon-coins',
                  'icon-kiosk-icon-card': card.icon === 'icon-kiosk-icon-card',
                }"
              ></i>
              {{ card.label | translate }}
            </div>
          </div>
        </div>

        <!-- Payment component without template (on site) -->
        <app-component-factory
          *ngIf="selectedCard && selectedCard.hasNextScreen === false"
          [componentClass]="selectedCard.paymentMethod.panelComponent"
          [data]="selectedCard.paymentMethod.data"
        ></app-component-factory>
      </div>
    </ng-container>

    <!--  Choices -->
    <ng-container *ngIf="step === kioskPaymentMethodPickerStep.TERMINAL">
      <div class="flex flex-grow flex-col items-center justify-center">
        <div
          class="flex w-8/12 flex-col items-center justify-center gap-12"
          [style.color]="headingColor | contentBuilderColor"
        >
          <app-component-factory
            *ngIf="selectedCard && selectedCard.hasNextScreen === true"
            [componentClass]="selectedCard.paymentMethod.panelComponent"
            [data]="selectedCard.paymentMethod.data"
          ></app-component-factory>

          <div
            *ngIf="payOnKioskText"
            class="text-center text-3xl"
          >
            {{ payOnKioskText | translate }}
          </div>

          <img
            *ngIf="displayPayOnKioskImage && payOnKioskImage"
            [src]="payOnKioskImage.url"
            alt="img"
          />
        </div>
      </div>
    </ng-container>
  }

  <!-- Footer -->
  <div class="grid grid-cols-2 gap-10 p-10">
    <app-button-atom
      size="XXL"
      thickSize="XXL"
      type="kiosk-light"
      [label]="'cart-funnel.buttons.cancel' | translate"
      (clickEventEmitter)="back()"
    ></app-button-atom>
    <app-button-atom
      *ngIf="displaySubmitButton"
      size="XXL"
      thickSize="XXL"
      type="kiosk-primary"
      [label]="'cart-funnel.buttons.validate' | translate"
      [disabled]="!selectedCard || !isOrderValid"
      (clickEventEmitter)="submit()"
    ></app-button-atom>
  </div>
</div>
