@let finalPrice = (order$ | async)?.final_price;

@if (finalPrice) {
  <div
    class="flex w-full flex-row items-center justify-between gap-5 p-5 font-bold"
    [style.backgroundColor]="primaryButtonBackgroundColor | contentBuilderColor"
    [style.color]="primaryButtonTextColor | contentBuilderColor"
  >
    <div class="text-4xl">{{ 'kiosk.cart.totalOrder' | translate }}</div>
    <div>
      @let cartProducts = cartProducts$ | async;
      <app-price-atom
        [format]="true"
        [amount]="finalPrice && cartProducts?.length ? finalPrice : 0"
        size="3XL"
      ></app-price-atom>
    </div>
  </div>
}

<div class="grid grid-cols-2 gap-10 p-10">
  <app-button-atom
    size="XXL"
    thickSize="XXL"
    label="Retour"
    [overrideBgColor]="secondaryButtonBackgroundColor"
    [overrideTextColor]="secondaryButtonTextColor"
    type="kiosk-light"
    [full]="true"
    (click)="back()"
  ></app-button-atom>

  <app-button-atom
    size="XXL"
    label="Payer"
    thickSize="XXL"
    [overrideBgColor]="primaryButtonBackgroundColor"
    [overrideTextColor]="primaryButtonTextColor"
    [disabled]="!(order$ | async)?.final_price"
    type="kiosk-primary"
    [full]="true"
    (click)="navigateToFunnel()"
  ></app-button-atom>
</div>
