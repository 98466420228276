import { Component, Input } from '@angular/core';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-price-atom',
  templateUrl: './price-atom.component.html',
})
export class PriceAtomComponent {
  @Input() amount!: number;
  @Input() discount = false;
  @Input() format = false;
  @Input() bold = true;
  @Input() semiBold = false;
  @Input() size?: 'SM' | 'MD' | 'L' | 'XL' | '2XL' | '3XL' | '4XL';
  @Input() displayPlus = false;
  @Input() withParenthesis = false;

  currency$ = this.marketplaceSelector.selectMarketplaceCurrency;

  constructor(private marketplaceSelector: MarketplaceStoreSelector) {}
}
