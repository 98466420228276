import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { ProductStoreSelector } from '@common/selectors/product.selector';
import {
  CartItemKeys,
  ReconstructedCartItemKeys,
  ReconstructedCartKeys,
} from '@config/keys/cart.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { DoodReconstructedCart, DoodReconstructedCartItem } from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ItemEditModalComponent } from '@shared/modals/item-edit-modal/item-edit-modal.component';
import { debounceTime, map, of, Subject, switchMap, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-kiosk-cart-products-list-block',
  templateUrl: './kiosk-cart-products-list-block.component.html',
})
export class KioskCartProductsListBlockComponent implements OnInit, OnDestroy {
  @Input() title?: string;
  @Input() primaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() backgroundColor?: IContentBuilderFieldColor;

  private _destroyerRef = new Subject<boolean>();

  reconstructedCart$ = this.cartService.getCartLineItems$();
  reconstructedShareOrderBasket$ = this.basketRefiner.selectCartItemsGroupedByUser;
  cartProducts$ = this.cartRefiner.selectActiveCartProducts;
  cartItemKeys = CartItemKeys;
  productKeys = ProductKeys;
  reconstructedCartKeys = ReconstructedCartKeys;
  reconstructedCartItemKeys = ReconstructedCartItemKeys;
  manageProductQuantity$ = new Subject<{ quantity: number; item: any }>();
  itemBeingUpdated: string[] = [];

  allproducts?: DoodProductModel[];

  constructor(
    private readonly cartService: CartService,
    private readonly basketRefiner: BasketStoreRefiner,
    private readonly cartRefiner: CartStoreRefiner,
    private readonly productSelector: ProductStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly cartDispatcher: CartStoreDispatcher,
    private readonly ordersService: OrdersService,
  ) {
    this.productSelector.selectProducts
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(products => (this.allproducts = products));
  }

  ngOnInit() {
    this.manageProductQuantity$
      .pipe(
        debounceTime(600),
        switchMap(({ quantity, item }) => {
          this.itemBeingUpdated = this.itemBeingUpdated.filter(itemId => itemId != item.id);
          if (quantity > 0) {
            this.cartService.updateCartItem({ ...item, quantity }, false);
            return of(null);
          } else {
            return this.cartService.removeItem$(item.id as number);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  copyCart(cart: any) {
    return { ...cart };
  }

  findProductById(products: DoodReconstructedCart, productId: string) {
    return products.shop.products.find(product => product.id === productId)?.name;
  }

  findByIdFromProducts(productId: string): DoodProductModel | undefined {
    return this.allproducts?.find(product => product.id === productId);
  }

  changeNbProduct(quantity: number, item: any): void {
    this.itemBeingUpdated = [];
    this.itemBeingUpdated.push(item.id);
    this.manageProductQuantity$.next({ quantity, item });
  }

  isBeingUpdated(itemId: string | number) {
    return this.itemBeingUpdated.includes(`${itemId}`);
  }

  onUpdateItem(itemId: number | string): void {
    ItemEditModalComponent.navigate = false;
    ItemEditModalComponent.backgroundColor = this.backgroundColor;
    ItemEditModalComponent.textColor = this.textColor;
    ItemEditModalComponent.buttonBackgroundColor = this.primaryButtonBackgroundColor;
    ItemEditModalComponent.buttonTextColor = this.primaryButtonTextColor;
    this.modalsService.open(ItemEditModalComponent.handle);
    this.cartService.loadShopsOfCart$().pipe(takeUntil(this._destroyerRef)).subscribe();
    this.cartService
      .getCartLineItems$()
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        map(reconstructedCart => this.lookForItemInCart(itemId, reconstructedCart)),
        map(cartItem => {
          if (cartItem) {
            this.cartDispatcher.updateStore({ edit_item: cartItem });
            this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();
          }
        }),
      )
      .subscribe();
  }

  private lookForItemInCart(
    itemId: number | string,
    reconstructedCart: DoodReconstructedCart[],
  ): null | DoodReconstructedCartItem {
    for (const cartOfShop of reconstructedCart) {
      const cartItem = cartOfShop[ReconstructedCartKeys.Items].find(
        elem => elem[ReconstructedCartItemKeys.Item][CartItemKeys.Id] === itemId,
      );
      if (cartItem) {
        return cartItem;
      }
    }

    return null;
  }
}
